
// Vue reactivity
import { ref, reactive, defineComponent, watchEffect, computed } from 'vue';

// icons
import { thumbsUpOutline, thumbsDownOutline, add, close } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter,
        IonSpinner, IonItem, IonLabel, IonIcon,
        IonThumbnail, IonAvatar, IonButtons, IonButton, IonInput, IonTextarea,
        IonList, IonListHeader, IonDatetime, IonCheckbox, IonSelect, IonSelectOption,
        modalController, loadingController, } from '@ionic/vue';
import Datepicker from 'vue3-datepicker';

// API services
import BookingService from '@/services/BookingService';

import moment from 'moment';
import { utils } from '@/composables/utils';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'BookingModal',
  props: {
    editingBooking: null,
  },
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter,
                IonSpinner, IonItem, IonLabel, IonIcon,
                IonThumbnail, IonAvatar, IonButtons, IonButton, IonInput, IonTextarea,
                IonList, IonListHeader, IonDatetime, IonCheckbox, IonSelect, IonSelectOption, Datepicker },
  setup(props) {
    const store = useStore();
    const { t } = useI18n();
    const { formatDate, presentToast } = utils();
    
    // 1. declare state variables (ref to make them reactive)
    const user = computed(() => store.state.user);
    const booking = reactive({
      id: null,
      customerName: user.value.firstName || "",
      contactPhone: user.value.phone || "",
      customerEmail: user.value.email || "",
      date: "",
      startTime: "",
      duration: 30,
      customerNote: "",
      selectedServices: [],
    });
    //const allServices = computed(() => store.getters.services);
    const fetchedBookingServices = computed(() => store.state.fetchedBookingServices);
    const bookingServices = computed(() => store.state.bookingServices);
    const availableDates: any = ref([]);
    const availableTimeSlots: any = ref([]);
    const isEditing = ref(false);
    const loadingOccupiedTimeSlots = ref(true);
    const occupiedTimeSlots = ref({});
    const openingHour = moment(10, 'H'); // opens at 10:00
    const closingHour = moment(20, 'H'); // closes at 20:00

    const minDate = moment(), maxDate = moment().add(7, 'days'), dates = [];
    for (let m = moment(minDate); m.isBefore(maxDate); m.add(1, 'days')) {
      dates.push( { text: m.format('YYYY-MM-DD (ddd)'), value: m.format('MM/DD/YYYY') } );
    }
    availableDates.value = dates;

    if (props.editingBooking) { // editing post
      isEditing.value = true;
      watchEffect(() => {
        const { id, customerName, contactPhone, customerEmail } = props.editingBooking.value;
        booking.id = id;
        booking.customerName = customerName;
        booking.contactPhone = contactPhone;
        booking.customerEmail = customerEmail;
      })
    }
    BookingService.getUpcomingOccupiedTimeSlots().then(res => {
      loadingOccupiedTimeSlots.value = false;
      occupiedTimeSlots.value = res;
    });

    // methods or filters
    const closeModal = async (data: any = {}) => {
      await modalController.dismiss(data);
    };

    const createNewBooking = async (booking: any) => {
      const loading = await loadingController.create({});
      await loading.present();
      const res = await BookingService.createNewBooking(booking);
      //console.log(res);
      // TODO: handle failed booking (time slot got occupied by others)
      loading.dismiss();
      presentToast( t('successCreateBooking'), 2000 );
      closeModal({ newBookingCreated: true });
    };

    const updateBooking = async() => {
      const loading = await loadingController.create({});
      await loading.present();
      const res = BookingService.updateBooking();
      loading.dismiss();
      presentToast( t('successUpdateBooking'), 2000 );
      closeModal();
    }

    const isTimeSlotOccupied = (unavailableTimeSlots: any, startTime: any, endTime: any) => {
      const parsedStartTime = moment(startTime).add(1, 'minute'); // assume the min timeslot unit will not be less than 1 minute
      const parsedEndTime = moment(endTime).subtract(1, 'minute');
      for (const slot of unavailableTimeSlots) {
        const slotStart = moment(slot.startTime, 'HH:mm:ss'), slotEnd = moment(slotStart).add(slot.duration, 'minute');
        if (parsedStartTime.isBetween(slotStart, slotEnd) || parsedEndTime.isBetween(slotStart, slotEnd)) {
          return true; // exclusive checking
        }
      }
      return false;
    }
    const onBookingDateChanged = async (e: any) => {
      const unavailableTimeSlots = occupiedTimeSlots.value[e.target.value] || [];
      const timeSlots = [];
      for (let h = moment(openingHour); h.isBefore(closingHour); h.add(booking.duration, 'm')) {
        const endTime = moment(h).add(booking.duration, 'm');
        //if (!isTimeSlotOccupied(unavailableTimeSlots, h, endTime)) { // timeslot is available
          timeSlots.push({
            text: `${h.format('HH:mm')} - ${endTime.format('HH:mm')}`,
            value: h.format('HH:mm')
          });
        //}
      }
      availableTimeSlots.value = timeSlots;
    }

    if (!fetchedBookingServices.value) {
      BookingService.getBookingServices().then(res => {
        store.commit('receiveBookingServices', res);
      })
    }

    // 3. return variables & methods to be used in template HTML
    return {
      t,

      // icons
      thumbsUpOutline, thumbsDownOutline, add, close,

      // variables
      isEditing, booking, bookingServices, fetchedBookingServices,
      availableDates, availableTimeSlots,
      loadingOccupiedTimeSlots, occupiedTimeSlots,
      minDate: moment().format("YYYY-MM-DD"), maxYear: new Date().getFullYear() + 1,

      // methods
      formatDate, closeModal, createNewBooking, updateBooking, onBookingDateChanged,
    }
  }
});
