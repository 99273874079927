import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fcb64e9a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "ion-text-center ion-margin-top"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_list_header = _resolveComponent("ion-list-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_datetime = _resolveComponent("ion-datetime")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_textarea = _resolveComponent("ion-textarea")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_footer = _resolveComponent("ion-footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            (_ctx.isEditing)
              ? (_openBlock(), _createBlock(_component_ion_title, { key: 0 }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('updateBooking')), 1)
                  ]),
                  _: 1
                }))
              : (_openBlock(), _createBlock(_component_ion_title, { key: 1 }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('newBooking')), 1)
                  ]),
                  _: 1
                })),
            _createVNode(_component_ion_buttons, { slot: "end" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, {
                  slot: "icon-only",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal()))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, { icon: _ctx.close }, null, 8, ["icon"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, { fullscreen: true }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_list, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_list_header, { color: "primary" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('CheckoutPage.contactInfo')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_ion_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, { position: "stacked" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('customerName')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_input, {
                  placeholder: _ctx.t('CheckoutPage.enterCustomerName'),
                  modelValue: _ctx.booking.customerName,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.booking.customerName) = $event))
                }, null, 8, ["placeholder", "modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, { position: "stacked" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('contactPhone')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_input, {
                  placeholder: _ctx.t('CheckoutPage.enterContactPhone'),
                  inputmode: "numeric",
                  modelValue: _ctx.booking.contactPhone,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.booking.contactPhone) = $event))
                }, null, 8, ["placeholder", "modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, { position: "stacked" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('customerEmail')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_input, {
                  placeholder: _ctx.t('CheckoutPage.enterCustomerEmail'),
                  type: "email",
                  modelValue: _ctx.booking.customerEmail,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.booking.customerEmail) = $event))
                }, null, 8, ["placeholder", "modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ion_list, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_list_header, { color: "primary" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('bookingDetails')), 1)
              ]),
              _: 1
            }),
            _createElementVNode("div", null, [
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, { position: "stacked" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('bookingDate')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_datetime, {
                    modelValue: _ctx.booking.date,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.booking.date) = $event)),
                    "display-format": "DD MMM YYYY (DDD)",
                    "picker-format": "YYYY MMM DD",
                    min: _ctx.minDate,
                    max: _ctx.maxYear,
                    onIonChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onBookingDateChanged($event)))
                  }, null, 8, ["modelValue", "min", "max"])
                ]),
                _: 1
              }),
              (_ctx.booking.date)
                ? (_openBlock(), _createBlock(_component_ion_item, { key: 0 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, { position: "stacked" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t('bookingTime')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_select, {
                        placeholder: _ctx.t('selectBookingTime'),
                        interface: "popover",
                        modelValue: _ctx.booking.startTime,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.booking.startTime) = $event))
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableTimeSlots, (timeSlot) => {
                            return (_openBlock(), _createBlock(_component_ion_select_option, {
                              key: timeSlot.value,
                              value: timeSlot.value
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(timeSlot.text), 1)
                              ]),
                              _: 2
                            }, 1032, ["value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["placeholder", "modelValue"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, { position: "stacked" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('remark')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_textarea, {
                    modelValue: _ctx.booking.customerNote,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.booking.customerNote) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_ion_list, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_list_header, { color: "primary" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('services')), 1)
              ]),
              _: 1
            }),
            (!_ctx.fetchedBookingServices)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createVNode(_component_ion_spinner)
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bookingServices, (service) => {
                    return (_openBlock(), _createBlock(_component_ion_item, {
                      key: service.id
                    }, {
                      default: _withCtx(() => [
                        _withDirectives(_createElementVNode("input", {
                          type: "checkbox",
                          slot: "start",
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.booking.selectedServices) = $event)),
                          name: "selectedServices",
                          value: service.title
                        }, null, 8, _hoisted_3), [
                          [_vModelCheckbox, _ctx.booking.selectedServices]
                        ]),
                        _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(service.title), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]))
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_footer, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            (_ctx.isEditing)
              ? (_openBlock(), _createBlock(_component_ion_button, {
                  key: 0,
                  expand: "block",
                  onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.updateBooking()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('updateBooking')), 1)
                  ]),
                  _: 1
                }))
              : (_openBlock(), _createBlock(_component_ion_button, {
                  key: 1,
                  expand: "block",
                  onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.createNewBooking(_ctx.booking)))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('createBooking')), 1)
                  ]),
                  _: 1
                }))
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}